<!-- 奖金计算 -->
<template>
  <div class="">
    <jinheader></jinheader>

    <div class="clear"></div>
  </div>
  <router-view />
  <jinfooter></jinfooter>
</template>

<script>
  //import '../js/font.js' //js文件引入，注意路径
  //import {myfun} from '../js/test.js' //js文件引入，注意路径
  //import Introduction from '@/views/Introduction.vue'
  //import api from '@/js/method'
  import jinheader from "@/components/jin/jin-header.vue";
  import jinfooter from "@/components/Footer.vue";
  export default {
    name: "Number-select",
    components: {
      jinheader,
      jinfooter,
    },
    data() {
      // 数据源
      return {};
    },
    mounted() {
      this.star()
    },
    computed: {

    },
    methods: {
      star() {
        document.documentElement.scrollTop = 0;
      }
      // 函数体部分，js的主要逻辑控制
    },
    props: {},
  };
</script>

<style scoped>
  /* @import './swiper.css'; */
  .clear {
    clear: both;
  }
</style>